import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import AnimatedHeading from '../components/AnimatedHeading';
import BlockContent from '../components/BlockContent';
import RegisterInterest from '../components/forms/RegisterInterest';
import FadeIn from '../components/Animations/FadeIn';
import Gallery from '../components/Gallery';
import ProjectPagination from '../components/ProjectPagination';

export const query = graphql`
	query projectTemplateQuery($_id: String!) {
		project: sanityProject(_id: { eq: $_id }) {
			_id
			title
			_rawBody
			status
			location
			mainImage {
				asset {
					url
				}
			}
			gallery {
				images {
					image {
						asset {
							gatsbyImageData(
								layout: FULL_WIDTH
								fit: FILLMAX
								placeholder: DOMINANT_COLOR
							)
						}
					}
					width
				}
			}
			showRegisterForm
			seo {
				metaTitle
				metaDesc
				shareGraphic {
					asset {
						url
					}
				}
			}
		}
	}
`;

const ProjectTemplate = ({ data, pageContext }) => {
	const project = data && data.project;
	return (
		<>
			<SEO
				title={project.seo.metaTitle || project.title}
				pathname={'/projects/' + project.slug}
				desc={project.seo.metaDesc}
				image={
					project.seo.shareGraphic?.asset?.url ||
					project.mainImage?.asset?.url
				}
			/>
			<AnimatedHeading text={project.title} />
			<section className="px-6 md:px-8">
				<div className="grid grid-cols-2 md:grid-cols-12 gap-6 md:gap-8 border-t border-white border-opacity-10 pt-6 md:pt-8">
					<div className="col-span-2 md:col-span-6 richtext">
						<FadeIn>
							{project._rawBody && (
								<BlockContent blocks={project._rawBody || []} />
							)}
						</FadeIn>
					</div>
					<div className="hidden md:block md:col-span-2"></div>
					{project.location && (
						<div className="md:col-span-2">
							<FadeIn>
								<h2 className="text-xs uppercase tracking-widest font-sans font-bold mb-3 opacity-50">
									Location
								</h2>
								<p>{project.location}</p>
							</FadeIn>
						</div>
					)}
					{project.status && (
						<div className="md:col-span-2">
							<FadeIn>
								<h2 className="text-xs uppercase tracking-widest font-sans font-bold mb-3 opacity-50">
									Status
								</h2>
								<p>{project.status}</p>
							</FadeIn>
						</div>
					)}
				</div>
			</section>
			{project.gallery && (
				<section className="p-6 md:p-8">
					<Gallery images={project.gallery.images} />
				</section>
			)}
			{project.showRegisterForm && (
				<FadeIn>
					<section className="px-6 md:px-8 py-16 md:py-32">
						<div className="max-w-xl mx-auto richtext">
							<h2 className="mb-8 text-center">
								Register your interest
							</h2>
							<RegisterInterest />
						</div>
					</section>
				</FadeIn>
			)}
			{pageContext.next && (
				<FadeIn>
					<ProjectPagination pageContext={pageContext} />
				</FadeIn>
			)}
		</>
	);
};

export default ProjectTemplate;
