import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import FadeIn from '../Animations/FadeIn';

import './style.scss';
export default function Gallery(props) {
	return (
		<div className="grid grid-cols-2 gap-6 md:gap-8 items-center">
			{props.images.map((image) => (
				<div className={image.width}>
					<FadeIn>
						<GatsbyImage
							image={image.image.asset.gatsbyImageData}
							alt={image.altText}
							className=" w-full h-full"
						/>
					</FadeIn>
				</div>
			))}
		</div>
	);
}
