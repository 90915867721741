import * as React from 'react';
import { Link } from 'gatsby';

export default function ProjectPagination({ pageContext }) {
	return (
		<section className="w-full px-6 md:px-8 py-64 text-sm border-t border-white border-opacity-10">
			<div className="text-center max-w-3xl mx-auto">
				<h2 className="font-sans text-xs mb-8 uppercase tracking-widest">
					Next Project
				</h2>
				<Link
					to={`/projects/` + pageContext.next.slug.current}
					className="link"
				>
					<span className="h2">{pageContext.next.title}</span>
				</Link>
			</div>
		</section>
	);
}
